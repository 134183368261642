<template>
  <div style="width: 100vw; height: 100vh; overflow-y: hidden">
    <div class="top"></div>
    <div class="bottom"></div>
    <img class="loginLogoImg" src="../../assets/loginLogo.png" />
    <img class="loginBackImg" src="../../assets/newBgImg.png" />
    <div class="loginView">
      <input
        class="login-input"
        placeholder="Email"
        v-model="form.account_name"
      />
      <input
        class="login-input"
        show-password="false"
        type="password"
        placeholder="Password"
        v-model="form.account_pwd"
      />
      <el-select placeholder="Type" v-model="form.type" style="width: 100%">
        <el-option value="学生"></el-option>
        <el-option value="讲师"></el-option>
        <el-option value="助教"></el-option>
      </el-select>
      <div class="login-btn" @click="submit">Login</div>
    </div>

    <div v-show="!isChrome" class="navigator-tip">
      ALERT: you are NOT using Google Chrome to visit this site, which might
      cause unexpected misfunctions or bugs. The developer of this system
      requires visiting via Chrome and will not be responsible for any errors on
      non-Chrome platforms.
    </div>
  </div>
</template>

<script>
import {
  loginByEducator,
  loginByStudent,
  loginByEmployee,
} from "../../api/index";
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setApplicationExtraEvents,
  setAppId,
  setAccountName,
  setApplicationEvents,
  setAvatarUrl,
  logout,
  getBrowserType,
} from "../../utils/store";
import "../../assets/common/font.css";
export default {
  name: "Login",
  data() {
    return {
      form: {
        account_name: "",
        account_pwd: "",
        type: "学生",
      },
      isChrome: false,
    };
  },
  mounted() {
    //先清除一下数据
    logout();

    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });
    let browser = getBrowserType();
    if (browser === "CHROME") {
      this.isChrome = true;
    }
  },
  methods: {
    submit() {
      if (!this.form.account_name) {
        this.$message.error("Please input email");
        return;
      }

      if (!this.form.account_pwd) {
        this.$message.error("Please input password");
        return;
      }

      if (this.form.type === "讲师") {
        loginByEducator(this.form.account_name, this.form.account_pwd).then(
          (res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "login success",
                type: "success",
              });
              setUserId(res.data.data._id.$id);
              setUserName(
                res.data.data.educator_first_name +
                  " " +
                  res.data.data.educator_last_name
              );
              setUserType("educator");
              this.$router.push("/teacherlist");
            } else {
              this.$message.warning("login fail");
            }
          }
        );
      } else if (this.form.type === "学生") {
        loginByStudent(
          getProjectCode(),
          this.form.account_name,
          this.form.account_pwd
        ).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "login success",
              type: "success",
            });
            var result = res.data.data;
            var application_extra_events = result.application_extra_events;
            setApplicationExtraEvents(application_extra_events);
            var application_events = result.application_events;
            setApplicationEvents(application_events);
            var app_id = result._id.$id;
            setAppId(app_id);
            var account_name = result["student_info"]["account_name"];
            setAccountName(account_name);
            var avatar_url = result["student_info"]["avatar_url"];
            setAvatarUrl(avatar_url);
            setUserId(res.data.data.student_id);
            setUserType("student");
            var student_name =
              res.data.data.student_info.student_lastName +
              res.data.data.student_info.student_givenName;
            setUserName(student_name);
            this.$router.push("/home");
          } else {
            this.$message.warning("Login failed: " + res.data.msg);
          }
        });
      } else if (this.form.type === "助教") {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(
          (res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "login success",
                type: "success",
              });
              setUserId(res.data.data._id.$id);
              setUserType("employee");
              setUserName(
                res.data.data.user_lastName + res.data.data.user_givenName
              );
              this.$router.push("/employeelist");
            } else {
              this.$message.warning("login fail");
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  height: 60px;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  background: #218393;
}
.bottom {
  height: 60px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  background: #218393;
}
.loginBackImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}

.loginLogo {
  width: 220px;
  position: absolute;
  z-index: 4;
  top: 100px;
  left: 150px;
}
.login-btn {
  width: 100%;
  text-align: center;
  font-size: 1.1vw;
  background: #218393;
  height: 2.8vw;
  color: white;
  line-height: 2.8vw;
  margin-top: 40px;
  cursor: pointer;
}

.login-title {
  color: #ffffff;
  font-size: 0.728vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.login-input {
  margin-top: 2px;
  box-sizing: border-box;
  border: 2px solid #218393;
  font-size: 1.1vw;
  width: 100%;
  height: 2.8vw;
  margin-top: 30px;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 15px;
  color: #218393;
}

.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}
.loginView {
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 30px;
  width: 28vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loginLogoImg {
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-top: 120px;
}
</style>
<style lang="scss">
.el-select {
  .el-input__inner {
    margin-top: 2px;
    box-sizing: border-box;
    border: 2px solid #218393;
    font-size: 1.1vw;
    width: 100%;
    height: 2.8vw;
    margin-top: 30px;
    background-color: transparent;
    outline: none;
    border-radius: 0px;
    padding: 0 15px;
    color: #218393;
  }
  .el-input__suffix {
    line-height: 2.8vw;
  }
}
</style>
